.DateInput_input {
  font-weight: 400;
  font-size: 1rem;
}

.DateInput_input__focused {
  border-bottom: 2px solid #4e66f8;
}

.DateRangePickerInput {
  border-radius: 0.4rem;
}

.SingleDatePickerInput {
  border-radius: 0.4rem;
}

.DateRangePickerInput .DateInput {
  margin-left: 5px;
}

.SingleDatePickerInput {
  padding-left: 5px;
  padding-right: 5px;
}

.DateRangePickerInput__withBorder {
  border: 1px solid #ced4da;
}

.SingleDatePickerInput__withBorder {
  border: 1px solid #ced4da;
}
